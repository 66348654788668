import * as paper from 'paper';

if(typeof window !== "undefined") window.paper = paper;

class LineCanvas {
  constructor(canvas, setCloseDetected, setImage) {
    this.paperScope = new paper.PaperScope();
    this.paperScope.setup(canvas);
    this.setCloseDetected = setCloseDetected;
    this.setImage = setImage;
    this.canvas = canvas;
    this.paths = {
      main: null,
      handles: [],
      arrow: null,
      coloredPaths: []
    };
    this.isInteracting = false;
    this.lastPointsLength = 0;
  }

  remove() {
    if (this.paperScope) {
      // First clean up any paths/handles
      if (this.paperScope.project) {
        this.paperScope.project.activeLayer.removeChildren();
      }
      this.paperScope.remove();
    }
  }

  draw(props) {
    const points = props.points
    if(props.readOnly === undefined) props.readOnly = true
    const scale = props.scale || 1

    this.paperScope.activate()

    // Only clear and recreate paths if number of points changed
    if (!this.paths.main || this.paths.handles.length !== points.length) {
      this.paperScope.project.activeLayer.removeChildren();
      this.paths.main = new this.paperScope.Path();
      this.paths.handles = [];
      this.paths.coloredPaths = [];
      this.paths.arrow = null;
    }

    // Update main path
    this.paths.main.segments = points;
    this.paths.main.strokeWidth = props.accStrokeWidth || 6 * scale;

    // Always use simple styles for maximum performance
    this.paths.main.strokeColor = '#70bd74';
    this.paths.main.shadowBlur = 0;
    this.paths.main.shadowOffset = null;
    this.paths.main.shadowColor = null;

    const to = !props.readOnly && points.length > 2 
      ? points.length - 2 
      : points.length;
    
    // Simple smoothing always
    if (points.length > 1) {
      this.paths.main.smooth({  
        factor: 0.5,
        type: 'catmull-rom' 
      });
    }
    this.lastPointsLength = points.length;

    // Only show selection handles when not interacting
    this.paths.main.fullySelected = !props.readOnly && !this.isInteracting;

    // draw an arrow to show the direction of the path 
    if(points.length > 1) {
      const startPoint = this.paths.main.segments[0].point;
      const endPoint = this.paths.main.segments[1].point;
      const vector = endPoint.subtract(startPoint);
      const arrow = vector.normalize(props.arrowSize || 25 * scale);

      if (!this.paths.arrow) {
        this.paths.arrow = new this.paperScope.Path();
      }

      this.paths.arrow.segments = [
        startPoint.add(arrow.rotate(150)),
        startPoint,
        startPoint.add(arrow.rotate(-150))
      ];
      this.paths.arrow.strokeWidth = props.arrowStrokeWidth || 8 * scale;
      this.paths.arrow.strokeCap = 'round';
      this.paths.arrow.strokeColor = props.arrowStrokeColor || '#a4d037';
    } else if (this.paths.arrow) {
      this.paths.arrow.remove();
      this.paths.arrow = null;
    }

    // Handle circles management
    if (props.readOnly || this.isInteracting) {
      // Clear handles in readonly mode or during interaction
      while (this.paths.handles.length > 0) {
        this.paths.handles.pop().remove();
      }
    } else {
      // Update handle circles for edit mode when not interacting
      while (this.paths.handles.length < points.length) {
        const handle = new this.paperScope.Path.Circle({
          radius: 12,
          visible: true
        });
        this.paths.handles.push(handle);
      }

      points.forEach((point, index) => {
        const handle = this.paths.handles[index];
        handle.position = this.paths.main.segments[index].point;
        handle.fillColor = index === props.selectedPointIndex 
          ? 'rgba(255, 255, 255, 0.8)' 
          : 'rgba(255, 255, 255, 0.3)';
        handle.strokeColor = index === props.selectedPointIndex 
          ? 'rgba(255, 255, 255, 1)' 
          : 'rgba(255, 255, 255, 0.5)';
        handle.strokeWidth = index === props.selectedPointIndex ? 3 : 2;
        
        if (!handle.onClick) {
          handle.onClick = () => {
            if (props.onPointClick) {
              props.onPointClick(index);
            }
          };
        }
      });

      // Remove excess handles
      while (this.paths.handles.length > points.length) {
        this.paths.handles.pop().remove();
      }
    }

    // Update colored paths - simplified during interaction
    let coloredPathIndex = 0;
    if (!this.isInteracting) {
    points.forEach((p, pIndex) => {
      if(!p.type || !pIndex) return;

      const from = this.paths.main.segments[pIndex - 1];
      const to = this.paths.main.segments[pIndex];
      let color;

      switch(p.type) {
        case "brake": 
            color = props.brakeStrokeColor || "#b65656";
          break;
        case "coast": 
            color = props.coastStrokeColor || "yellow";
          break;
        default:
          return;
      }

      let coloredPath;
      if (coloredPathIndex < this.paths.coloredPaths.length) {
        coloredPath = this.paths.coloredPaths[coloredPathIndex];
      } else {
        coloredPath = new this.paperScope.Path();
        this.paths.coloredPaths.push(coloredPath);
      }

      coloredPath.segments = [from, to];
        coloredPath.strokeWidth = props.coloredStrokeWidth || 4 * scale;
      coloredPath.strokeColor = color;
      coloredPathIndex++;
    });

    // Remove excess colored paths
    while (this.paths.coloredPaths.length > coloredPathIndex) {
      this.paths.coloredPaths.pop().remove();
      }
    }

    // Detect if racing path is closed
    const isClosed = points.length > 5 
      && this.paths.main.firstSegment.point.getDistance(this.paths.main.lastSegment.point) < 30;
    if(typeof this.setCloseDetected === "function") this.setCloseDetected(isClosed);

    this.paperScope.view.scale(scale);
    this.paperScope.view.draw();
    
    if (!this.isInteracting) {
      this.buildImage(props.readOnly);
    }
  }

  setInteracting(interacting) {
    this.isInteracting = interacting;
  }

  buildImage(readOnly) {
    if(typeof this.setImage !== "function" || !readOnly) return
    this.setImage(this.canvas.toDataURL())
  }
  
}

export default LineCanvas;

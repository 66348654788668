import React, { useState, useEffect, useContext } from 'react';
import LineCanvas from './LineCanvas';

const LineThumb = (props) => {
    const canvasRef = React.createRef()
    
    useEffect(() => {
        if(!props.points || props.points.length <= 1) return

        const lineCanvas = new LineCanvas(canvasRef.current)
        
        // Find the bounds of the track
        const bounds = props.points.reduce((acc, p) => {
            acc.minLat = Math.min(acc.minLat, p.lat || 0)
            acc.maxLat = Math.max(acc.maxLat, p.lat || 0)
            acc.minLng = Math.min(acc.minLng, p.lng || 0)
            acc.maxLng = Math.max(acc.maxLng, p.lng || 0)
            return acc
        }, { minLat: 90, maxLat: -90, minLng: 180, maxLng: -180 })
        
        // Add some padding
        const padding = 0.1
        const latSpan = bounds.maxLat - bounds.minLat
        const lngSpan = bounds.maxLng - bounds.minLng
        bounds.minLat -= latSpan * padding
        bounds.maxLat += latSpan * padding
        bounds.minLng -= lngSpan * padding
        bounds.maxLng += lngSpan * padding
        
        // Convert coordinates to canvas space
        const points = props.points.map(p => {
            const lat = p.lat || 0
            const lng = p.lng || 0
            return {
                x: ((lng - bounds.minLng) / (bounds.maxLng - bounds.minLng)) * props.width,
                y: ((lat - bounds.minLat) / (bounds.maxLat - bounds.minLat)) * props.height,
                type: p.type
            }
        })
        
        lineCanvas.draw({
            points, 
            readOnly: true,
            fitBounds: false,
            scale: 1,
            accStrokeWidth: 5,
            accStrokeGradient: {
                stops: ['#57a86f', '#238a39']
            },
            brakeStrokeColor: "#d66d6d",
            coastStrokeColor: "#bcdf5c",
            arrowSize: 15,
            arrowStrokeWidth: 5,
            arrowStrokeColor: "#238a39",
            coloredStrokeWidth: 5,
            shadowColor: "#eee",
            shadowBlur: 2,
            shadowOffset: {x: 3, y: 3}
        })

        return () => lineCanvas.remove()
    }, [props.points])

    return (
        <canvas 
            ref={canvasRef} 
            width={props.width} 
            height={props.height}
            style={{
                width: props.width,
                height: props.height,
                display: 'block'
            }}
        ></canvas>
    )
};

export default LineThumb;
